import React from "react";
import { navigate } from "gatsby";
import queryString from "query-string";

// seo
import Seo from "../components/Seo";

// components
import { memberDetails } from "../components/AboutUs/MemberDetails";
import AboutUsDetail from "../components/AboutUs/AboutUsDetail";
import GetInTouch from "../components/Home/GetInTouch/GetInTouch";

const AboutUsDetails = props => {
  const { name } = queryString.parse(props.location.search);

  const details = memberDetails.filter(item => item.id === name);

  return (
    <>
    <Seo
        title="About us | Reverse Diabetes, Obesity, PCOS & More"
        description="AI-driven treatment to reverse Diabetes, Hypertension, Obesity & Other Chronic Conditions. 97% reduced blood sugar & meds, 98% eliminated symptoms. Start today."
      />
      <AboutUsDetail
        payload={details.length > 0 ? details[0] : {}}
        handleBack={() => navigate("/about-us/")}
      />
      <GetInTouch />
    </>
  );
};

export default AboutUsDetails;
